import axios from "axios";
/* const baseURL = process.env.REACT_APP_API_URL ?? 'http://localhost:3001/api' */
const baseURL = "https://app3.developerpa.com/";
// const baseURL = "https://demo-railway-test-production.up.railway.app/";

const getTokenData = () => {
  const token = sessionStorage.getItem("newCheckToken") ?? "";
  return token;
};
const getMode = () => {
  const mode = sessionStorage.getItem("mode") ?? "";
  return mode;
};
const headers = {
  "Content-Type": "application/json; charset=utf-8",
  Authorization: "",
};

const SERVICE = () => axios.create({ baseURL, headers });

const AUTHSERVICE = () => {
  const token = getTokenData();
  let authHeaders = headers;
  if (token !== null) {
    authHeaders = {
      "Content-Type": "application/json; charset=utf-8",
      accessToken: `${token}`,
    };
  }
  return axios.create({ baseURL, headers: authHeaders });
};

const SELECT_SERVICES = () => {
  const token = getTokenData();
  const mode = getMode();
  let authHeaders = headers;
  if (token !== null) {
    authHeaders = {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `${token}`,
      tag: `${mode}`,
    };
  }
  return axios.create({ baseURL, headers: authHeaders });
};

const AUTHSERVICE_FORMDATA = () => {
  const token = getTokenData();
  let authHeaders = headers;
  if (token !== null) {
    authHeaders = {
      "Content-Type": "multipart/form-data; charset=utf-8",
      accessToken: `${token}`,
    };
  }
  return axios.create({ baseURL, headers: authHeaders });
};

export { SERVICE, AUTHSERVICE, AUTHSERVICE_FORMDATA, SELECT_SERVICES };

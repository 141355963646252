import "./Navbar.scss";
import BackIcon from "../../assets/icons/back.svg";
import CloseButton from "../../assets/icons/closeButton.svg";

import { useLocation, useNavigate } from "react-router-dom";
import ErrorCToast from "../ErrorToast/ErrorCToast";
import {
  setFormTrackInfo,
  setNewNaturalStep,
} from "redux/features/newNaturalClient";
import { onboardingTrack } from "service/auth/auth";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../icons";
const Navbar = ({ title, noBackbutton, noHomebutton }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { step, trackData } = useSelector((store) => store.newNaturalClient);
  const { message } = trackData;

  // const handleBack = async (view) => {
  //   const body = { view };
  //   try {
  //     const res = await onboardingTrack(body);
  //     const data = res.data;
  //     if (res.data.nextView === "1") {
  //       // paso 1 - vista onboarding / Estatus
  //       return navigate("/pasos", { state: res.data });
  //     }
  //     dispatch(setFormTrackInfo(data));
  //     dispatch(setNewNaturalStep(parseInt(data.nextView)));
  //   } catch (error) {
  //     ErrorCToast(error);
  //   }
  // };

  const handleBack = () => {
    // Navegar a la vista anterior sin hacer ninguna llamada a la API
    navigate("/exit"); // Esto regresará a la última ruta en el historial
  };

  return (
    <div className="form-navbar">
      {!noBackbutton &&
        step !== 4 &&
        trackData?.message !== "Datos marcados como incorrectos" &&
        !pathname.includes("/formulario") && (
          <div className="back-button" onClick={() => handleBack(step - 1)}>
            <img src={BackIcon} alt="backArrow" />
          </div>
        )}
      {pathname.includes("/formulario") ? (
        <div className="title formone">{title}</div>
      ) : (
        <div className="title">{title}</div>
      )}

      {!noHomebutton &&
        trackData?.message !== "Datos marcados como incorrectos" &&
        !pathname.includes("/formulario") && (
          // <div className="house-button" onClick={() => handleBack(1)}>
          //   <Icon name="StepsHouseIcon" />
          // </div>
          <div className="house-button" onClick={() => handleBack(0)}>
          <img src={CloseButton} alt="CloseButton" />
        </div>
        )}

      {message && trackData.message === "Datos marcados como incorrectos" && (
        <div className="house-button" onClick={() => handleBack(4)}>
          <img src={CloseButton} alt="CloseButton" />
        </div>
      )}

      {pathname.includes("/formulario") && (
        <div className="house-button" onClick={() => handleBack(1)}>
          <img src={CloseButton} alt="CloseButton" />
        </div>
      )}
    </div>
  );
};
Navbar.displayname = "Navbar";
export default Navbar;

import React, { memo } from "react";
import "./Loading.scss";
import { Spinner } from "@chakra-ui/react";
import { Card, Row } from "antd";

const LoadingOnb = memo(() => {
 
  return (
    <div className="loanding">
   
      <Card className="card-datos" title="Almacenando datos...">
        <Row gutter={[16, 16]}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="UniOrange.500"
            size="xl"
          />
        </Row>
      </Card>
    </div>
  );
});
LoadingOnb.displayName = "LoadingOnb";
export default LoadingOnb;
